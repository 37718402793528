<template>
  <div class="main-animation abs"
       :style="position"
  >
    <transition name="fade-long">
      <div v-if="withFade" class="main-animation__fade"></div>
    </transition>
    <video autoplay
           muted
           loop
           playsinline
           class="main-animation__video"
           @loadeddata="withFade=false">
      <source :src="'/upload/product_video/' + video" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  name: "main-animation",
  data: () => {
    return {
      withFade: false,
    }
  },
  props: ["video", "position"],

  mounted() {
    console.log('video:', this.video);
    console.log('height:', this.position);
  }
}
</script>

<style lang="scss">
.main-animation {
  width: 800px;
  height: 90%;

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

</style>